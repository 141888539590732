import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
    constructor(private translateService: TranslateService) {
        this.translateService.addLangs(["en", "pt"]);
        this.translateService.setDefaultLang("pt");

        const browserLang = translateService.getBrowserLang();
        // this.translate.use('pt');
        this.translateService.use(browserLang.match(/en|pt/) ? browserLang : "en");
    }

    ngOnInit() {
        console.log("Facetec SDK Version; ", FaceTecSDK.version(), 1004);
    }
}
