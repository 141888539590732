import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractHttpService } from "./http-abstract-service";
import { SubmitData } from "../dto";
import { Config as FacetecConfig } from "src/assets/Config";

@Injectable({
    providedIn: "root",
})
export class HttpFacetecService extends AbstractHttpService {
    constructor(http: HttpClient) {
        super(http, "");
    }

    public start(ref: string) {
        const path = `/${ref}/start`;
		return super.post(path, null);
    }

    public submit(ref: String, data: SubmitData) {
        const path = `/${FacetecConfig.transactionId}/${ref}`;
        return super.post(path, data);
    }

    public getUsers(filter: String, currentPage: number, pageSize: number) {
        const path = `/admin/getAll/${filter}/${currentPage}/${pageSize}`;
        return super.get(path);
    }
}
