import { Component, OnInit, ViewChild } from "@angular/core";
import { UserData } from "src/app/dto";
import { HttpFacetecService } from "src/app/service/http-facetec.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { BASE_PATH } from "src/app/service/http-abstract-service";

@Component({
  selector: "app-user-table",
  templateUrl: "./user-table.component.html",
  styleUrls: ["./user-table.component.scss"],
})
export class UserTableComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  allUsers: UserData[] = [];
  displayUsers: UserData[] = [];
  selectedUser: UserData | null = null;

  loading: boolean = false;
  imagesData: Array<string> = [];

  totalItems: number = 0;
  totalPages: number = 0;
  pageSize: number = 20;
  currentPage: number = 0;

  rowsPerPageOptions: number[] = [20, 50, 100];

  sendingInvite: boolean = false;
  phoneNumber: string = '';

  searchQuery = '';
  currentFilter = 'registered';

  constructor(private facetecService: HttpFacetecService) { }

  ngOnInit() {
    this.loadUsers('registered');
  }

  openSendInvite(): void {
    this.sendingInvite = !this.sendingInvite;
    this.phoneNumber = `244${this.selectedUser.phoneNumber}`;
  }

  sendInvite() {
    console.log('Sending invite to user: ', this.selectedUser, 'Phone number: ', this.phoneNumber);
    if (!this.selectedUser?.member?.hash || !this.phoneNumber) {
      console.error('Missing required information');
      return;
    }
  
    const baseUrl = 'https://cadastro.mpla.ao/';
    const referenceParam = `?ref=${this.selectedUser.member.hash}`;
    const fullRegistrationUrl = baseUrl + referenceParam;
  
    const inviteMessage = `Por favor faça o seu pré-cadastramento para o VIII Congresso do MPLA. ${fullRegistrationUrl}`;
    
    const whatsAppUrl = `https://wa.me/${this.phoneNumber}?text=${encodeURIComponent(inviteMessage)}`;
    
    window.open(whatsAppUrl, '_blank');
  }

  loadUsers(filter: string) {
    this.loading = true;
    this.allUsers = [];
    this.displayUsers = [];
    this.facetecService.getUsers(filter, this.currentPage, this.pageSize).subscribe((data: any) => {
      this.allUsers = data.data.customerDataRecords.map((user: any) => {
        return {
          ...user,
          createdAt: `${new Date(user.createdAt || 0).toLocaleDateString()} ${new Date(user.createdAt || 0).toLocaleTimeString()}`,
          updatedAt: `${new Date(user.updatedAt || 0).toLocaleDateString()} ${new Date(user.updatedAt || 0).toLocaleTimeString()}`,
        }
      });
      this.displayUsers = this.allUsers;
      this.totalItems = data.data.totalElements;
      this.totalPages = data.data.totalPages
      this.loading = false;
    });
  }

  onRowClick(user: UserData) {
    this.selectedUser = user;
    this.loading = false;
    this.sendingInvite = false;
    this.phoneNumber = '';
    const imagesUrls = user.uploadDocuments.map((doc) => {
      return `${BASE_PATH}/image/${user.transactionId}/${doc.id}`;
    })

    console.log("Images requests: ", imagesUrls);
    this.imagesData = imagesUrls;
  }

  onPageChange(page: number) {
    if (page >= 0 && page < this.totalPages) {
      this.currentPage = page;
      this.loadUsers(this.currentFilter);
    }
  }

  getPages(): number[] {
    const totalPages = Math.ceil(this.totalItems / this.pageSize);
    return Array.from({ length: totalPages }, (_, i) => i + 1);
  }

  filterBy(filter: string) {
    this.currentFilter = filter;
    this.currentPage = 0;
    if (filter === 'registered') {
      this.loadUsers('registered');
    } else if (filter === 'recent') {
      this.loadUsers('recent');
    } else if (filter === 'invited') {
      this.loadUsers('invited');
    }
    this.applySearch();
  }

  applySearch() {
    const query = this.searchQuery.toLowerCase();
    this.displayUsers = this.allUsers.filter((user) =>
      ['firstName', 'lastName', 'idNumber', 'email', 'phoneNumber'].some(
        (key) => user[key]?.toLowerCase().includes(query)
      )
    );
  }

  inviteUser(user: any) {
    // Logic for inviting a user
    console.log(`Inviting user: ${user.firstName} ${user.lastName}`);
  }

  setPageSize(option: number): void {
    this.pageSize = option;
    this.loadUsers(this.currentFilter);
  }
}
