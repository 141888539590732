import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class DashboardUserService {
    private storageKey = 'dashboardUserKey';
    user: string | null = null;

    setUser(user: string) {
        sessionStorage.setItem(this.storageKey, user);
    }

    getUser(): string | null {
        return sessionStorage.getItem(this.storageKey);
    }
}
